export default class BaseValidator {
  constructor({inputName, name, value}) {
    this.inputName = inputName
    this.name = name
    this.value = value
  }

  getErrorMessages() {
    throw new Error("'getErrorMessages' hasn't been implemented")
  }

  getName() {
    return this.name
  }

  getValue() {
    return this.value
  }

  isValid() {
    throw new Error("'isValid' hasn't been implemented")
  }
}
