import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStepSuccess extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step_success"})

    this.t = t
  }

  render() {
    const {t} = this.tt

    return (
      <View dataSet={{component: "plans--sign-up-modal--step-success"}}>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading style={{textAlign: "center"}}>
            {t(".upgrade_complete")}
          </Heading>
        </View>
        <Text style={{marginBottom: 20, fontSize: 20, textAlign: "center"}}>
          {t(".thank_you_for_your_order")}
        </Text>
        <Text style={{marginBottom: 30, fontSize: 20, textAlign: "center"}}>
          {t(".we_will_send_the_invoice_to_the_given_email")}
        </Text>
        <Text style={{marginBottom: 30, fontSize: 20, textAlign: "center"}}>
          {t(".the_upgrade_is_now_active")}
        </Text>
        <Button
          color="green"
          dataSet={{class: "close-window-button"}}
          fontStyle={{fontSize: "20px"}}
          onPress={this.p.onRequestClose}
          style={{width: "100%"}}
          title={t(".close_window")}
        />
      </View>
    )
  }
}))
