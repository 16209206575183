import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Card from "components/card"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import React from "react"

export default memo(shapeComponent(class ComponentsFrontLayoutMainCard extends ShapeComponent {
  render() {
    const {className, children, ...restProps} = this.props

    return (
      <div className="align-center" style={{width: "100%", height: "100%"}}>
        <Card className={classNames("components--main-card", className)} style={{flexGrow: 1}} {...restProps}>
          {children}
        </Card>
      </div>
    )
  }
}))
