import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BreadCrumbPoint from "./bread-crumb-point"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalBreadCrumbs extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.bread_crumbs"})
    const {step} = this.p
    let active
    let choiceOfPlanDone = false
    let periodDone = false
    let paymentMethodDone = false
    let paymentInformationDone = false
    const confirmationDone = false

    if (step == "step1") {
      active = "choice-of-plan"
    } else if (step == "step2") {
      active = "period"
      choiceOfPlanDone = true
    } else if (step == "step3") {
      active = "payment-method"
      choiceOfPlanDone = true
      periodDone = true
    } else if (step == "step-credit-card" || step == "step-invoice-info" || step == "step-ean-invoice-info") {
      active = "payment-information"
      choiceOfPlanDone = true
      periodDone = true
      paymentMethodDone = true
    } else if (step == "step-confirm") {
      active = "confirmation"
      choiceOfPlanDone = true
      periodDone = true
      paymentMethodDone = true
      paymentInformationDone = true
    }

    return (
      <View dataSet={{component: "plans--sign-up-modal--bread-crumbs"}} style={{flexDirection: "row", justifyContent: "space-evenly", width: "100%"}}>
        <BreadCrumbPoint active={active} done={choiceOfPlanDone} first identifier="choice-of-plan" label={t(".choice_of_plan")} />
        <BreadCrumbPoint active={active} done={periodDone} identifier="period" label={t(".period")} />
        <BreadCrumbPoint active={active} done={paymentMethodDone} identifier="payment-method" label={t(".payment_method")} />
        <BreadCrumbPoint active={active} done={paymentInformationDone} identifier="payment-information" label={t(".payment_information")} />
        <BreadCrumbPoint active={active} done={confirmationDone} identifier="confirmation" label={t(".confirmation")} last />
      </View>
    )
  }
}))
