import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Country} from "models"
import {dig} from "diggerize"
import globalEvents from "shared/events"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import React from "react"
import useCurrentCountry from "components/utils/use-current-country"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
export default memo(shapeComponent(class ComponentsFrontLayoutCountrySelect extends ShapeComponent {
  setup() {
    this.currentCountry = useCurrentCountry()
    this.currentUser = useCurrentUser()
  }

  render() {
    return (
      <HayaSelect
        defaultValue={this.currentUser?.currentCountryId() || this.currentCountry?.id()}
        id="country_select"
        onChange={this.tt.onCountryChanged}
        options={this.tt.countryOptions}
      />
    )
  }

  onCountryChanged = async ({options}) => {
    const option = dig(options, 0)
    const country = option?.country
    const countryId = option?.value

    if (!countryId) return

    await this.tt.currentUser.update({current_country_id: countryId})

    globalEvents.emit("currentCountryChanged", {country})
  }

  countryOptions = async ({searchValue, values}) => {
    let query = this.props.query?.clone() || Country

    query = query
      .ransack({s: "current_translation_name"})
      .select({Country: ["countryCode", "id", "name"]})

    if (searchValue) {
      query.ransack({
        "g": [
          {
            "m": "or",
            "c": [
              {
                "a": {"0": {"name": "id"}},
                "p": "cont",
                "v": {"0": {"value": searchValue}}
              },
              {
                "a": {"0": {"name": "country_code"}},
                "p": "cont",
                "v": {"0": {"value": searchValue}}
              }
            ]
          }
        ]
      })
    }

    if (values) query.ransack({id_eq_any: values})

    const countries = await query.toArray()

    return countries.map((country) => ({
      country,
      text: country.name(),
      value: country.id()
    }))
  }
}))
