import React, {useRef} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digg} from "diggerize"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import globalState from "./global-state"
import memo from "set-state-compare/src/memo"
import MenuContent from "./menu-content"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {StyleSheet} from "react-native"

const styles = StyleSheet.create({
  icon: {color: "#000", cursor: "pointer", fontSize: 36}
})

export default memo(shapeComponent(class ComponentsSchoolClassesLayoutSideBarMenuButton extends ShapeComponent {
  static propTypes = propTypesExact({
    active: PropTypes.string,
    onClosed: PropTypes.func.isRequired,
    onOpened: PropTypes.func.isRequired,
    onUpgradePressed: PropTypes.func.isRequired
  })

  setup() {
    this.setInstance({rootRef: useRef()})
    this.useStates({menuOpened: digg(globalState, "menuOpened")})
  }

  render() {
    const {active} = this.props

    return (
      <div className="components--front-layout--side-bar-menu-button" ref={this.tt.rootRef}>
        {!this.s.menuOpened &&
          <div className="menu-button" onClick={this.tt.onMenuButtonClicked}>
            <FontAwesomeIcon name="bars" style={styles.icon} />
          </div>
        }
        {this.s.menuOpened &&
          <MenuContent active={active} onCloseMenuClicked={this.tt.onCloseMenuClicked} onUpgradePressed={this.p.onUpgradePressed} />
        }
      </div>
    )
  }

  onCloseMenuClicked = (e) => {
    e.preventDefault()
    this.tt.closeMenu()
  }

  onMenuButtonClicked = (e) => {
    e.preventDefault()
    this.tt.openMenu()
  }

  closeMenu = () => {
    this.setState({menuOpened: false})
    globalState.menuOpened = false
    localStorage.setItem("sideBarMenuOpened", "false")
    this.p.onClosed()
  }

  openMenu = () => {
    this.setState({menuOpened: true})
    globalState.menuOpened = true
    localStorage.setItem("sideBarMenuOpened", "true")
    this.p.onOpened()
  }
}))
