import {Contact, InvoiceInfo} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import InputValidator from "shared/input-validator"
import memo from "set-state-compare/src/memo"
import React from "react"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStepEanInvoiceInfo extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step_ean_invoice_info"})

    this.t = t
    this.useStates({
      schoolName: "",
      eanNumber: "",
      note: ""
    })
  }

  render() {
    const {t} = this.tt

    return (
      <View dataSet={{component: "plans--sign-up-modal--step-ean-invoice-info"}}>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading>
            {t(".invoice_information")}
          </Heading>
        </View>
        <UtilsInput
          dataSet={{class: "school-name-input"}}
          label={t(".school_name")}
          onChangeText={this.setStates.schoolName}
          style={{marginBottom: 15}}
          value={this.s.schoolName}
        />
        <UtilsInput
          dataSet={{class: "ean-number-input"}}
          label={t(".ean_number")}
          onChangeText={this.setStates.eanNumber}
          style={{marginBottom: 15}}
          value={this.s.eanNumber}
        />
        <UtilsInput
          dataSet={{class: "note-input"}}
          label={t(".note_or_other")}
          onChangeText={this.setStates.note}
          style={{marginBottom: 15}}
          value={this.s.note}
        />
        <UtilsButton dataSet={{class: "submit-ean-invoice-info-button"}} onPress={this.tt.onContinuePressed} title={t(".continue")} />
      </View>
    )
  }

  onContinuePressed = () => {
    const {eanNumber, note, schoolName: name} = this.s
    const invoiceInfo = {
      eanNumber,
      name,
      note
    }

    const inputValidator = new InputValidator({
      inputs: invoiceInfo,
      translations: {
        eanNumber: InvoiceInfo.humanAttributeName("eanNumber"),
        name: Contact.humanAttributeName("name")
      }
    })

    inputValidator
      .validate("eanNumber", {presence: true})
      .validate("name", {presence: true})

    if (inputValidator.isInvalid()) {
      FlashMessage.alert(inputValidator.getErrorMessages().join(" "))
    } else {
      this.p.onEanInvoiceInfoSubmit({invoiceInfo})
    }
  }
}))
