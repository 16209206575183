import {Currency} from "models"
import Deserializer from "@kaspernj/api-maker/build/deserializer"
import {useState} from "react"

const defaultCurrency = () => {
  const currencyData = globalThis.CURRENTS.currency

  if (!currencyData) return null

  const parsedCurrencyData = Deserializer.parse(currencyData)

  // Might be a collection with preloaded relationships
  if (Array.isArray(parsedCurrencyData)) return parsedCurrencyData[0]

  const currency = new Currency({data: parsedCurrencyData})

  return currency
}

const useCurrentCurrency = () => {
  const [currentCurrency] = useState(() => defaultCurrency())

  // It should be possible to change the currency currency and this hook is kind of a placeholder for that case.

  return currentCurrency
}

export default useCurrentCurrency
