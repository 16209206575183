import {Image, View} from "react-native"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Checkmark from "./checkmark.png"
import memo from "set-state-compare/src/memo"
import React from "react"
import {Text} from "shared/base"

export default memo(shapeComponent(class ComponentsPlansSignUpModalBreadCrubPoint extends ShapeComponent {
  render() {
    const {done, first, label, last} = this.props
    const active = this.isActive()
    const circleStyle = {
      width: 32,
      height: 32,
      backgroundColor: "#ccc",
      borderRadius: "50%",
      zIndex: 2
    }

    if (active) {
      circleStyle.border = "4px solid #50b14e"
    } else if (done) {
      circleStyle.backgroundColor = "#50b14e"
    }

    const lineStyle = {position: "absolute", bottom: 14, zIndex: 1, width: "100%", borderBottom: "1px solid #ccc"}

    if (first) {
      lineStyle.width = "50%"
      lineStyle.right = 0
    } else if (last) {
      lineStyle.width = "50%"
      lineStyle.left = 0
    }

    return (
      <View dataSet={{component: "plans--sign-up-modal--bread-crumb-point"}} style={{flex: 1, alignItems: "center"}}>
        <Text style={{marginBottom: 5, fontSize: 11, whiteSpace: "nowrap"}}>
          {label}
        </Text>
        <View style={circleStyle}>
          <Image source={Checkmark} />
        </View>
        <View style={lineStyle} />
      </View>
    )
  }

  isActive = () => this.p.active == this.p.identifier
}))
