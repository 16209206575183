import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import React from "react"
import TextComponentReplace from "shared/text-component-replace"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsUsersAcceptTermsHint extends ShapeComponent {
  render() {
    const {t} = useI18n({namespace: "js.components.users.accept_terms_hint"})
    const replaces = [
      {
        component: (
          <a href={t(".privacy_policy_link")} key="privacy-policy" target="_blank">
            {t(".privacy_policy")}
          </a>
        ),
        text: "%{privacy_policy}"
      },
      {
        component: (
          <a href={t(".terms_of_service_link")} key="terms-of-service" target="_blank">
            {t(".terms_of_service")}
          </a>
        ),
        text: "%{terms_of_service}"
      }
    ]

    return (
      <TextComponentReplace
        replaces={replaces}
        text={t(".click_here_to_read_the_privacy_policy_and_terms_of_service")}
      />
    )
  }
}))
