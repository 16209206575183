import {Account, SchoolClass} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import BreadCrumbs from "./bread-crumbs"
import classNames from "classnames"
import FlashMessage from "shared/flash-message"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import Modal from "components/modal"
import PropTypes from "prop-types"
import React from "react"
import Step1 from "./step1"
import Step2 from "./step2"
import Step3 from "./step3"
import StepConfirm from "./step-confirm"
import StepCreditCard from "./step-credit-card"
import StepEanInvoiceInfo from "./step-ean-invoice-info"
import StepInvoiceInfo from "./step-invoice-info"
import StepSuccess from "./step-success"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModal extends ShapeComponent {
  static propTypes = {
    schoolClass: PropTypes.instanceOf(SchoolClass)
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.index"})

    this.setInstance({
      currentUser: useCurrentUser(),
      t
    })
    this.useStates({
      account: () => this.props.schoolClass?.school()?.account(),
      invoiceInfo: undefined,
      paymentMethod: undefined,
      plan: undefined,
      period: undefined,
      price: undefined,
      schoolClass: this.props.schoolClass,
      step: "step1"
    })
  }

  loadAccountIfNone() {
    if (!this.s.account) {
      this.loadAccount()
    }
  }

  async loadAccount() {
    const account = await Account
      .ransack({account_users_user_id_eq: this.tt.currentUser.id()})
      .first()

    if (!account) throw new Error(`Account couldn't be loaded for user ${this.tt.currentUser.email()}`)

    this.setState({account})
  }

  render() {
    const {className, onRequestClose, ...restProps} = this.props
    const modalProps = {}
    const {account, paymentMethod, period, plan, price, schoolClass, step} = this.s

    if (
      step == "step2" ||
      step == "step3" ||
      step == "step-ean-invoice-info" ||
      step == "step-invoice-info" ||
      step == "step-confirm" ||
      step == "step-success"
    ) {
      modalProps.maxWidth = 800
    } else {
      modalProps.wide = true
    }

    return (
      <Modal
        className={classNames("components--plans--sign-up-modal", className)}
        onBack={step == "step1" || step == "step-success" ? undefined : this.tt.onBackPressed}
        onRequestClose={onRequestClose}
        {...modalProps}
        {...restProps}
      >
        {step != "step-success" &&
          <View style={{marginBottom: 50}}>
            <BreadCrumbs step={step} />
          </View>
        }
        {step == "step1" &&
          <Step1 onPlanChoosen={this.tt.onPlanChoosen} onSchoolClassChanged={this.tt.onSchoolClassChanged} schoolClass={schoolClass} />
        }
        {step == "step2" &&
          <Step2 onBackPressed={this.tt.onBackPressed} onPeriodChosen={this.tt.onPeriodChosen} plan={plan} schoolClass={schoolClass} />
        }
        {step == "step3" &&
          <Step3 onPaymentMethodChosen={this.tt.onPaymentMethodChosen} schoolClass={schoolClass} />
        }
        {step == "step-credit-card" && account &&
          <StepCreditCard account={account} onCreditCardAdded={this.tt.onCreditCardAdded} />
        }
        {step == "step-invoice-info" &&
          <StepInvoiceInfo onInvoiceInfoSubmit={this.tt.onInvoiceInfoSubmit} schoolClass={schoolClass} />
        }
        {step == "step-ean-invoice-info" &&
          <StepEanInvoiceInfo onEanInvoiceInfoSubmit={this.tt.onEanInvoiceInfoSubmit} />
        }
        {step == "step-confirm" && account &&
          <StepConfirm
            onConfirmUpgrade={this.tt.onConfirmUpgrade}
            paymentMethod={paymentMethod}
            period={period}
            price={price}
            schoolClass={schoolClass}
          />
        }
        {step == "step-success" &&
          <StepSuccess onRequestClose={this.p.onRequestClose} />
        }
      </Modal>
    )
  }

  onBackPressed = () => {
    const {step} = this.s
    const {paymentMethod} = this.state

    if (step == "step2") {
      this.setState({step: "step1"})
    } else if (step == "step3") {
      this.setState({step: "step2"})
    } else if (step == "step-credit-card") {
      this.setState({step: "step3"})
    } else if (step == "step-invoice-info" || step == "step-ean-invoice-info") {
      this.setState({step: "step3"})
    } else if (step == "step-confirm") {
      if (paymentMethod.identifier() == "invoice") {
        this.setState({step: "step-invoice-info"})
      } else if (paymentMethod.identifier() == "ean_invoice") {
        this.setState({step: "step-ean-invoice-info"})
      } else if (paymentMethod.identifier() == "credit_card") {
        this.loadAccountIfNone()
        this.setState({step: "step-credit-card"})
      } else {
        throw new Error(`Unknown payment method: ${paymentMethod.identifier()}`)
      }
    } else {
      throw new Error(`Unknown step: ${step}`)
    }
  }

  onConfirmUpgrade = async () => {
    const {account, invoiceInfo} = this.s

    if (!account) throw new Error("No account set")

    try {
      await loading(this.t(".subscribe_loading"), async () => {
        await this.s.plan.subscribeTo({
          account,
          invoice_info: {
            city: invoiceInfo.city,
            ean_number: invoiceInfo.eanNumber,
            email: invoiceInfo.email,
            name: invoiceInfo.name,
            note: invoiceInfo.note,
            street_name: invoiceInfo.streetName,
            zip_code: invoiceInfo.zipCode,
            vat_number: invoiceInfo.vatNumber
          },
          payment_method: this.s.paymentMethod,
          period: this.s.period,
          price: this.s.price,
          school_class: this.s.schoolClass
        })
        FlashMessage.success(this.t(".you_have_been_subscribed_to_unicorn"))
        this.setState({step: "step-success"})
      })
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onCreditCardAdded = () => {
    this.setState({step: "step-confirm"})
  }

  onEanInvoiceInfoSubmit = ({invoiceInfo}) => {
    this.loadAccountIfNone()
    this.setState({
      invoiceInfo,
      step: "step-confirm"
    })
  }

  onInvoiceInfoSubmit = ({invoiceInfo}) => {
    this.loadAccountIfNone()

    if (this.s.paymentMethod.identifier() == "credit_card") {
      this.setState({invoiceInfo, step: "step-credit-card"})
    } else {
      this.setState({invoiceInfo, step: "step-confirm"})
    }
  }

  onPeriodChosen = ({period, price}) => {
    this.setState({
      period,
      price,
      step: "step3"
    })
  }

  onPaymentMethodChosen = ({paymentMethod}) => {
    if (paymentMethod.identifier() == "ean_invoice") {
      this.setState({paymentMethod, step: "step-ean-invoice-info"})
    } else if (paymentMethod.identifier() == "invoice") {
      this.setState({paymentMethod, step: "step-invoice-info"})
    } else if (paymentMethod.identifier() == "credit_card") {
      this.setState({paymentMethod, step: "step-invoice-info"})
    } else {
      throw new Error(`Unknown payment method: ${paymentMethod.identifier()}`)
    }
  }

  onPlanChoosen = ({plan}) => {
    if (!this.s.schoolClass) {
      FlashMessage.alert(this.t(".please_choose_a_class_to_upgrade"))

      return
    }

    this.setState({
      plan,
      step: "step2"
    })
  }

  onSchoolClassChanged = (schoolClass) => {
    this.setState({
      account: schoolClass.school()?.account(),
      schoolClass
    })
  }
}))
