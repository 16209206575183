import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import InputContainer from "./input-container"
import InputField from "./input-field"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import {useForm} from "@kaspernj/api-maker/build/form"

export default memo(shapeComponent(class ComponentsUtilsInput extends ShapeComponent {
  static defaultProps = {
    autoRows: false,
    required: false
  }

  static propTypes = {
    autoRows: PropTypes.bool.isRequired,
    dataSet: PropTypes.object,
    label: PropTypes.string,
    minimumRows: PropTypes.number,
    name: PropTypes.string,
    onChangeText: PropTypes.func,
    required: PropTypes.bool.isRequired,
    style: PropTypes.object,
    styles: PropTypes.object
  }

  setup() {
    const {name, value} = this.props

    this.setInstance({form: useForm()})

    // Set initial value in form
    useMemo(() => {
      if (this.form && name) {
        this.form.setValue(name, value)
      }
    }, [])
  }

  containerProps() {
    const {containerProps, dataSet, ...containerRestProps} = this.props

    return Object.assign({}, containerRestProps, containerProps)
  }

  render() {
    const {autoRows, dataSet, label, minimumRows, name, onChangeText, required, style, styles, value, ...restProps} = this.props
    const {component, ...restDataSet} = dataSet || {}
    const actualDataSet = Object.assign(
      {component: classNames("utils/input", component), name},
      restDataSet
    )
    const actualStyle = this.stylingFor("textInput", style)
    const textInputExtraProps = {}

    if (autoRows && this.props.multiline && !("rows" in this.props)) {
      // If no rows prop is passed, calculate the number of rows based on the value
      let rows = value ? value.split("\n").length : 1

      if (minimumRows && minimumRows > rows) {
        rows = minimumRows
      }

      if (!("whiteSpace" in actualStyle)) {
        actualStyle.whiteSpace = "nowrap"
      }

      textInputExtraProps.rows = rows
    }

    return (
      <InputContainer {...this.containerProps()}>
        <InputField
          dataSet={actualDataSet}
          onChangeText={this.tt.onChangeText}
          style={actualStyle}
          value={value}
          {...textInputExtraProps}
          {...restProps}
        />
      </InputContainer>
    )
  }

  onChangeText = (value) => {
    const {form} = this.tt
    const {name, onChangeText} = this.props

    if (form && name) {
      form.setValue(name, value)
    }

    if (onChangeText) {
      onChangeText(value)
    }
  }
}))
