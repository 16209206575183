import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import HayaSelect from "components/woof-haya-select"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import useInput from "@kaspernj/api-maker/build/use-input"
import {withSelectContainer as withAdminSelectContainer} from "components/admin/select-container"
import {withInputContainer} from "components/inputs/input-container"

const selectComponent = (selectComponentProps) => {
  const SelectComponent = memo(shapeComponent(class SelectComponent extends ShapeComponent {
    static defaultProps = {
      limit: 20
    }

    static propTypes = {
      className: PropTypes.string,
      query: PropTypes.object
    }

    setup() {
      const {inputProps, restProps} = useInput({props: this.props})
      const {ref, type, ...restInputProps} = inputProps

      this.setInstance({restInputProps, restProps})
    }

    render() {
      const {restInputProps, restProps} = this.tt
      const {className, label, limit, query, valueCallback, ...actualRestProps} = restProps

      return (
        <HayaSelect
          className={classNames("components--select-component", selectComponentProps.className, className)}
          options={this.tt.actualOptionsCallback}
          search
          {...restInputProps}
          {...actualRestProps}
        />
      )
    }

    actualOptionsCallback = async ({searchValue, values}) => {
      return await selectComponentProps.optionsCallback({
        limit: this.props.limit,
        query: this.props.query,
        searchValue,
        valueCallback: this.props.valueCallback,
        values
      })
    }
  }))

  return {
    base: SelectComponent,
    withAdminSelectContainer: withAdminSelectContainer(SelectComponent),
    withInputContainer: withInputContainer(SelectComponent),
    withMemo: SelectComponent
  }
}

export default selectComponent
