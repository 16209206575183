import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import AppHistory from "shared/history"
import changeLocale from "components/users/change-locale"
import {dig} from "diggerize"
import HayaSelect from "components/woof-haya-select"
import Locales from "shared/locales"
import memo from "set-state-compare/src/memo"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useLocale from "i18n-on-steroids/src/use-locale.mjs"

export default memo(shapeComponent(class ComponentsFrontLayoutLocaleSelect extends ShapeComponent {
  setup() {
    this.currentUser = useCurrentUser()
    this.locale = useLocale()

    this.localeOptions = useMemo(() => {
      const options = []

      for (const locale of Locales.availableLocales()) {
        options.push({
          text: Locales.labelForLocale(locale),
          value: locale
        })
      }

      return options
    }, [this.locale])
  }

  render() {
    return (
      <HayaSelect
        id="locale_select"
        onChange={this.tt.onLocaleChanged}
        options={this.tt.localeOptions}
        values={[this.tt.locale]}
      />
    )
  }

  onLocaleChanged = async (args) => {
    if (!args) return

    const locale = dig(args, "options", 0, "value")

    if (!locale) return

    await changeLocale(locale)

    const url = new URL(location.href)

    url.searchParams.append("translate_url_to_current_locale", locale)

    fetch(url.href)
      .then((response) => response.json())
      .then((response) => {
        AppHistory.replace(response.new_url)
      })
  }
}))
