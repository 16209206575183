import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import {PaymentMethod} from "models"
import RadioButton from "components/utils/radio-button"
import React from "react"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStep3 extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step3"})

    this.currentUser = useCurrentUser()
    this.t = t
    this.useStates({
      paymentMethod: undefined,
      paymentMethods: undefined
    })
  }

  componentDidMount() {
    this.loadPaymentMethods()
  }

  async loadPaymentMethods() {
    let paymentMethods = await PaymentMethod
      .ransack({s: "current_translation_name"})
      .select({PaymentMethod: ["id", "identifier", "name", "showForUsers"]})
      .toArray()

    // Only show EAN invoice to school classes from Denmark
    if (!this.isDanish()) {
      paymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.identifier() != "ean_invoice")
    }

    if (!isUserA(this.tt.currentUser, "tester")) {
      paymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.showForUsers())
    }

    this.setState({paymentMethods})
  }

  render() {
    return (
      <View>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading>{this.t(".how_do_you_want_to_pay")}</Heading>
          <View>
            {this.s.paymentMethods?.map((paymentMethod) =>
              <RadioButton
                checked={this.s.paymentMethod?.id() == paymentMethod.id()}
                dataSet={{class: "payment-method-radio-button", paymentMethodId: paymentMethod.id()}}
                fontStyle={{fontSize: 20}}
                key={paymentMethod.id()}
                label={paymentMethod.name()}
                onChange={this.tt.onPaymentMethodChanged}
                style={{marginBottom: 10}}
                value={paymentMethod}
              />
            )}
          </View>
        </View>
        <Button
          color="green"
          dataSet={{class: "choose-payment-method-button"}}
          fontStyle={{fontSize: "20px"}}
          onPress={this.tt.onChoosePaymentMethodPressed}
          style={{width: "100%", marginTop: 15}}
          title={this.t(".continue")}
        />
      </View>
    )
  }

  isDanish = () => this.p.schoolClass.school().country().countryCode() == "DK"

  onChoosePaymentMethodPressed = () => {
    if (!this.s.paymentMethod) {
      FlashMessage.alert(this.t(".please_choose_a_payment_method"))

      return
    }

    this.p.onPaymentMethodChosen({paymentMethod: this.s.paymentMethod})
  }

  onPaymentMethodChanged = ({value: paymentMethod}) => {
    this.setState({paymentMethod})
  }
}))
