import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import {HelperText} from "react-native-paper"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

export default memo(shapeComponent(class ComponentsUtilsRadioButton extends ShapeComponent {
  static propTypes = propTypesExact({
    checked: PropTypes.bool.isRequired,
    dataSet: PropTypes.object,
    fontStyle: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChangeChecked: PropTypes.func,
    style: PropTypes.object,
    value: PropTypes.any
  })

  setup() {
    const {checked, name, value} = this.props
    const {wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "input"}})
    const {errors} = digs(wrapperOpts, "errors")

    this.errors = errors
    this.form = useForm()

    // Set initial value in form
    useMemo(() => {
      if (this.form && checked && name) {
        this.form.setValue(name, value)
      }
    }, [])
  }

  render() {
    const {errors} = this.tt
    const {checked, dataSet, fontStyle, label, style} = this.props
    const actualDataSet = Object.assign({}, dataSet, {checked})

    const pressableStyle = Object.assign({}, style, {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    })

    const viewStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 17,
      height: 17,
      backgroundColor: "#cbcbcb",
      borderRadius: "50%"
    }

    if (checked) {
      viewStyle.backgroundColor = "#4c93ff"
    }

    actualDataSet.checked = checked

    return (
      <Pressable dataSet={actualDataSet} onPress={this.tt.onRadioButtonPressed} style={pressableStyle}>
        <View style={viewStyle} />
        {label &&
          <View style={{marginLeft: 12}}>
            <Text style={fontStyle}>
              {label}
            </Text>
          </View>
        }
        {errors.length > 0 &&
          <HelperText dataSet={{class: "errors-helper-text"}} type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </Pressable>
    )
  }

  onRadioButtonPressed = () => {
    const {form} = this.tt
    const {name} = this.props
    const newChecked = !this.p.checked

    if (form && name) {
      form.setValue(name, newChecked)
    }

    if (this.props.onChange) {
      this.p.onChange({
        checked: newChecked,
        label: this.p.label,
        value: this.props.value
      })
    }

    if (this.props.onChangeChecked) {
      this.p.onChangeChecked(newChecked)
    }
  }
}))
