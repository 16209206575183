import escapeStringRegexp from "escape-string-regexp"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"

const TextComponentReplace = ({replaces, text}) => {
  let regexText = "("
  let count = 0

  for (const replace of replaces) {
    if (count >= 1) regexText += "|"
    regexText += escapeStringRegexp(replace.text)
    count += 1
  }

  regexText += ")"

  const regex = new RegExp(regexText)
  const splitText = text.split(regex)
  const parts = []

  for (const splitPart of splitText) {
    const replace = replaces.find((replaceFind) => replaceFind.text == splitPart)

    if (replace) {
      parts.push(replace.component)
    } else {
      parts.push(splitPart)
    }
  }

  return parts
}

TextComponentReplace.propTypes = propTypesExact({
  replaces: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired
})

export default TextComponentReplace
