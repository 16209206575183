import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Account} from "models"
import Button from "components/inputs/button"
import classNames from "classnames"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import Routes from "shared/routes"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
export default memo(shapeComponent(class ComponentsBillingAddCreditCardButton extends ShapeComponent {
  static propTypes = {
    account: PropTypes.instanceOf(Account).isRequired,
    className: PropTypes.string,
    onCreditCardAdded: PropTypes.func
  }

  setup() {
    if (!this.p.account) throw new Error("No account given")

    const {t} = useI18n({namespace: "js.components.billing.add_credit_card_button"})

    this.setInstance({t})
  }

  render() {
    const {className, onCreditCardAdded, ...restProps} = this.props

    return (
      <Button
        className={classNames("components--billing--add-credit-card-button", className)}
        onClick={this.tt.onAddCreditCardClicked}
        {...restProps}
      >
        {this.t(".click_to_add_a_credit_card_to_your_account")}
      </Button>
    )
  }

  onAddCreditCardClicked = (e) => {
    e.preventDefault()

    window.onSuccessfullCallback = ({queryParams}) => {
      this.onCreditCardAdded(queryParams)
      delete window.onSuccessfullCallback
    }

    window.open(Routes.addCreditCardPath(), "_blank")
  }

  async onCreditCardAdded(addedQueryParams) {
    await loading(this.t(".adding_credit_card_to_your_account"), async () => {
      await this.p.account.addCreditCard({setup_intent: addedQueryParams.setup_intent})

      if (this.props.onCreditCardAdded) {
        this.props.onCreditCardAdded(addedQueryParams)
      }
    })
  }
}))
