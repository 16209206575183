import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import PeriodOption from "./period-option"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStep2 extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal"})

    this.setInstance({t})
    this.useStates(["planResource", "selectedPeriod", "selectedPrice"])

    useMemo(() => {
      this.loadPlan()
    }, [])
  }

  loadPlan = () => {
    const planResource = this.p.plan.resources().loaded().find((resource) => resource.resourceName() == "SchoolClass")

    this.setState({planResource})
  }

  render() {
    const {t} = this.tt

    return (
      <View dataSet={{component: "plans--sign-up-modal--step2"}}>
        <View style={{marginBottom: 20}}>
          <Text style={{fontSize: "32px", fontWeight: 700, textAlign: "center"}}>
            {t(".choose_period")}
          </Text>
        </View>
        <View style={{display: "flex", alignItems: "center"}}>
          {this.s.planResource?.periods()?.loaded()?.map((period) =>
            <View key={period.id()} style={{marginBottom: 10, width: 220}}>
              <PeriodOption
                onSelect={this.tt.onPeriodSelect}
                period={period}
                selected={this.s.selectedPeriod?.id() == period.id()}
              />
            </View>
          )}
        </View>
        <View style={{marginTop: 20, marginBottom: 20}}>
          <Text style={{fontSize: "20px", textAlign: "center"}}>
            {t(".the_subscription_will_renew_automatically")}
          </Text>
        </View>
        <Button
          color="green"
          dataSet={{component: "choose-period-button"}}
          fontStyle={{fontSize: "20px"}}
          onPress={this.tt.onChoosePeriodPressed}
          style={{width: "100%", marginBottom: "15px"}}
          title={t(".choose_period")}
        />
      </View>
    )
  }

  onChoosePeriodPressed = () => {
    if (!this.s.selectedPeriod) {
      FlashMessage.alert(this.t(".please_select_a_period_first"))

      return
    }

    this.p.onPeriodChosen({period: this.s.selectedPeriod, price: this.s.selectedPrice})
  }

  onGoBackPressed = () => {
    this.p.onBackPressed()
  }

  onPeriodSelect = ({period, price}) => {
    this.setState({selectedPeriod: period, selectedPrice: price})
  }
}))
