const validatorsRequireContext = require.context("./validators", true, /\.js$/)

export default class InputValidator {
  constructor({inputs, translations}) {
    this.errorMessages = []
    this.inputs = inputs
    this.translations = translations
    this.validations = {}
    this.validationsExecuted = false
  }

  getErrorMessages = () => this.errorMessages

  isValid() {
    if (!this.validationsExecuted) {
      this.runValidators()
    }

    return this.errorMessages.length === 0
  }

  isInvalid = () => !this.isValid()

  runValidators() {
    this.validationsExecuted = true

    for (const inputName in this.validations) {
      for (const validatorArgs of this.validations[inputName]) {
        const validatorName = validatorArgs.validatorName
        const requireName = `./${validatorName}.js`
        let ValidatorClass

        try {
          ValidatorClass = validatorsRequireContext(requireName).default
        } catch (error) {
          throw new Error(`Couldn't require '${requireName}' from: ${validatorsRequireContext.keys().join(", ")}`)
        }

        const value = this.inputs[inputName]
        const validator = new ValidatorClass({
          input: inputName,
          name: this.translations[inputName] || inputName,
          value
        })

        if (!validator.isValid()) {
          for (const errorMessage of validator.getErrorMessages()) {
            this.errorMessages.push(errorMessage)
          }
        }
      }
    }
  }

  validate(inputName, args) {
    for (const validatorName in args) {
      if (!(inputName in this.validations)) this.validations[inputName] = []

      this.validations[inputName].push({validatorName})
    }

    return this
  }
}
