import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {initialsForUser} from "shared/initials"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {User} from "models"

export default memo(shapeComponent(class ComponentsUsersCircleIcon extends ShapeComponent {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired
  }

  render() {
    const {className, user, ...restProps} = this.props

    return (
      <div className={classNames("components--users--circle-icon", className)} {...restProps}>
        {initialsForUser(user)}
      </div>
    )
  }
}))
