import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {ContactRelationship} from "models"
import CountrySelect from "components/front-layout/country-select"
import Devise from "@kaspernj/api-maker/build/devise"
import FlashMessage from "shared/flash-message"
import isUserA from "components/users/is-a"
import Link from "@kaspernj/api-maker/build/link"
import LocaleSelect from "components/front-layout/locale-select"
import memo from "set-state-compare/src/memo"
import Routes from "shared/routes"
import {Text} from "shared/base"
import useAnythingUnicorn from "components/subscriptions/use-anything-unicorn"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UsersCircleIcon from "components/users/circle-icon"

const MenuHeader = memo((props) =>
  <View
    dataSet={{class: "side-bar-menu-header"}}
    style={{
      marginBottom: -2,
      fontSize: 12
    }}
  >
    <Text style={{color: "#fff"}}>
      {props.children}
    </Text>
  </View>
)

const MenuSection = memo((props) =>
  <View dataSet={{class: "side-bar-menu-section"}} style={{marginBottom: 30}}>
    <Text>
      {props.children}
    </Text>
  </View>
)

const MenuItem = memo((props) => {
  const {active, children, className, identifier, label, target, to, ...restProps} = props
  const elementProps = {}
  let Element

  if (target == "_blank") {
    Element = "a"
    elementProps.href = to
  } else {
    Element = Link
    elementProps.to = to
  }

  if (active && identifier && active == identifier) elementProps["data-active"] = true

  return (
    <Element className={classNames("menu-item", "menu-item-link", className)} data-identifier={identifier} target={target} {...elementProps} {...restProps}>
      <div className="menu-item-title">
        {children}
      </div>
      {label &&
        <View
          dataSet={{class: "menu-item-label"}}
          style={{
            paddingHorizontal: 6,
            paddingVertical: 4,
            borderRadius: 6,
            marginLeft: 6,
            backgroundColor: "#d648dc",
            fontSize: 10
          }}
        >
          <Text>
            {label}
          </Text>
        </View>
      }
    </Element>
  )
})

export default memo(shapeComponent(class ComponentsFrontLayoutSideBarMenuButtonMenuContent extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.front_layout.side_bar_menu_button"})
    const currentUser = useCurrentUser()
    const {anythingUnicorn, hasUnicornTrial, schoolClassesWithUnicorn, schoolsWithUnicorn, userUnicornSubscriptions} = useAnythingUnicorn()
    let showUnicornUpgrade

    if (schoolsWithUnicorn?.length > 0 || schoolClassesWithUnicorn?.length > 0) {
      // It shouldn't show the upgrade prompt if the user is a member of a school or a class that is already on Unicorn.
      showUnicornUpgrade = false
    } else if (userUnicornSubscriptions?.length > 0 && !hasUnicornTrial) {
      // It shouldn't show the upgrade prompt if the user already has a personal Unicorn subscription that isn't a trial.
      showUnicornUpgrade = false
    } else {
      showUnicornUpgrade = true
    }

    this.setInstance({
      anythingUnicorn,
      currentUser,
      showUnicornUpgrade,
      t
    })
    this.useStates({
      schoolLeaderRoles: undefined
    })

    useMemo(() => {
      this.tt.loadSchoolLeaderRoles()
    }, [currentUser?.id()])
  }

  loadSchoolLeaderRoles = async () => {
    const schoolLeaderRoles = await ContactRelationship
      .ransack({
        child_id_eq: this.tt.currentUser?.contactId() || "no-user-fake-uuid",
        contact_relationship_type_identifier_eq: "school_leader"
      })
      .toArray()

    this.setState({schoolLeaderRoles})
  }

  render() {
    const {active} = this.p
    const {anythingUnicorn, currentUser, showUnicornUpgrade, t} = this.tt

    return (
      <div className="menu-content" data-anything-unicorn={anythingUnicorn}>
        <View>
          <View
            dataSet={{class: "menu-header"}}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              marginBottom: 25
            }}
          >
            <View dataSet={{class: "hide-menu-text-container"}} style={{fontSize: 12}}>
              <Text style={{color: "#fff"}}>
                {t(".hide_menu")}
              </Text>
            </View>
            <Pressable onPress={this.p.onCloseMenuClicked}>
              <i
                className="close-menu-icon fa fa-remove"
                style={{
                  marginLeft: "5px",
                  cursor: "pointer",
                  fontSize: "22px"
                }}
              />
            </Pressable>
          </View>
          {showUnicornUpgrade &&
            <View style={{display: "flex", flexDirection: "row", marginBottom: 40}}>
              <Pressable dataSet={{class: "side-bar-menu-upgrade-pressable"}} onPress={this.p.onUpgradePressed}>
                <Text
                  style={{
                    paddingTop: 11,
                    paddingRight: 20,
                    paddingBottom: 11,
                    paddingLeft: 20,
                    backgroundColor: "#d648dc",
                    borderRadius: 6,
                    color: "#fff",
                    fontSize: 16
                  }}
                >
                  {t(".upgrade")}
                </Text>
              </Pressable>
            </View>
          }
          <MenuHeader children={t(".woof")} />
          <MenuSection>
            <MenuItem
              active={active}
              children={t(".home")}
              className="class-overview-link"
              identifier="user-dashboard"
              to={Routes.userRootPath()}
            />
          </MenuSection>
          <MenuHeader children={t(".unicorn")} />
          <MenuSection>
            <MenuItem
              active={active}
              children={t(".status")}
              identifier="user-class-status"
              to={Routes.userClassStatusPath()}
            />
            <MenuItem
              active={active}
              children={t(".analysis")}
              identifier="user-unicorn"
              to={Routes.userUnicornPath()}
            />
            {isUserA(currentUser, "tester") &&
              <MenuItem
                active={active}
                children={t(".profile")}
                identifier="user-class-profile"
                to={Routes.userClassProfilePath()}
              />
            }
          </MenuSection>
          <MenuHeader children={t(".support")} />
          <MenuSection>
            <MenuItem
              children={t(".video_guides")}
              target="_blank"
              to={t(".video_guides_url")}
            />
          </MenuSection>
          {isUserA(currentUser, "admin") &&
            <>
              <MenuHeader children={t(".extras")} />
              <MenuSection>
                <MenuItem children={t(".admin")} to={Routes.adminRootPath()} />
              </MenuSection>
            </>
          }
          {this.s.schoolLeaderRoles?.length > 0 &&
            <>
              <MenuHeader children={t(".administration")} />
              <MenuSection>
                <MenuItem
                  active={active}
                  children={t(".school_classes")}
                  identifier="user-admin-school-classes"
                  to={Routes.userAdminSchoolClassesPath()}
                />
                <MenuItem
                  active={active}
                  children={t(".users")}
                  identifier="user-admin-users"
                  to={Routes.userAdminUsersPath()}
                />
                <MenuItem
                  active={active}
                  children={t(".subscription")}
                  identifier="user-admin-subscription"
                  to={Routes.userAdminSubscriptionPath()}
                />
              </MenuSection>
            </>
          }
        </View>
        <View style={{marginTop: "auto"}}>
          <View style={{marginBottom: 5}}>
            <CountrySelect />
          </View>
          <View>
            <LocaleSelect />
          </View>
          {currentUser &&
            <View style={{flexDirection: "row", alignItems: "center", marginTop: "10px"}}>
              <View>
                <View>
                  <Link
                    children={currentUser?.name() || t(".profile")}
                    className="menu-item-link"
                    to="#"
                  />
                </View>
                <View>
                  <Link
                    children={t(".my_profile")}
                    className="menu-item-link"
                    to={Routes.userProfilePath()}
                  />
                </View>
                <View>
                  <a
                    children={t(".sign_out")}
                    className="menu-item-link sign-out-item"
                    href="#"
                    onClick={this.tt.onSignOutClicked}
                  />
                </View>
              </View>
              <View style={{marginLeft: "auto"}}>
                <UsersCircleIcon user={currentUser} />
              </View>
            </View>
          }
        </View>
      </div>
    )
  }

  onSignOutClicked = async (e) => {
    e.preventDefault()

    try {
      await Devise.signOut()
      FlashMessage.success(this.t(".you_have_been_signed_out"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}))
