import globalEvents from "shared/events"
import I18n from "shared/i18n"
import {Platform} from "react-native"
import {User} from "models"
const changeLocale = async (locale) => {
  await User.setLocale({locale})
  I18n.setLocale(locale)

  if (Platform.OS == "web") {
    document.querySelector("html").setAttribute("lang", locale)
  }

  globalEvents.emit("localeChanged")
}

export default changeLocale
