import Devise from "@kaspernj/api-maker/build/devise"
const isUserA = (user, roleIdentifiers) => {
  if (typeof roleIdentifiers == "string") {
    roleIdentifiers = [roleIdentifiers]
  }

  if (!user) return false
  if (!Array.isArray(roleIdentifiers)) throw new Error(`roleIdentifier wasn't a string or an array: ${typeof roleIdentifier}`)

  const foundUserRole = user.userRoles().loaded().find((userRole) => roleIdentifiers.includes(userRole.role().identifier()))

  return Boolean(foundUserRole)
}

const isCurrentUserA = (roleIdentifier) => Devise.isUserSignedIn() && isUserA(Devise.currentUser(), roleIdentifier)

export {isCurrentUserA}
export default isUserA
