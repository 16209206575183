import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import MoneyFormatter from "@kaspernj/api-maker/build/money-formatter"
import {PlanResourcePeriod} from "models"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import RadioButton from "components/utils/radio-button"
import React from "react"
import useCurrentCurrency from "components/utils/use-current-currency"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class PeriodOption extends ShapeComponent {
  static propTypes = propTypesExact({
    onSelect: PropTypes.func.isRequired,
    period: PropTypes.instanceOf(PlanResourcePeriod).isRequired,
    selected: PropTypes.bool.isRequired
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.period_option"})

    this.setInstance({t})
    this.currentCurrency = useCurrentCurrency()
    this.price = this.priceForPeriod(this.p.period)

    if (!this.price) throw new Error(`No price for currency ${this.currentCurrency?.currencyCode()} could be found`)
  }

  render() {
    const {period} = this.p
    const priceLabel = MoneyFormatter.format(this.price?.price())
    let label

    if (period.hasName()) {
      label = period.name().replace("%{price}", priceLabel)
    } else {
      label = this.t(".x_months_for_price", {months: this.p.period.months(), price: priceLabel})
    }

    return (
      <View>
        <RadioButton
          checked={this.p.selected}
          dataSet={{component: "period-radio-button", periodId: this.p.period.id(), priceId: this.price?.id()}}
          fontStyle={{fontSize: "20px"}}
          label={label}
          onChange={this.tt.onRadioButtonChanged}
        />
      </View>
    )
  }

  onRadioButtonChanged = () => {
    this.p.onSelect({period: this.p.period, price: this.price})
  }

  priceForPeriod = () => {
    if (!this.currentCurrency) throw new Error("No current currency")

    return this.p.period.prices().loaded().find((price) => price.priceCurrency() == this.currentCurrency.currencyCode())
  }
}))
