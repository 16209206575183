import {Plan, SchoolClass, Subscription} from "models"
import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {dig} from "diggerize"
import Heading from "components/utils/heading"
import memo from "set-state-compare/src/memo"
import PlanCard from "./plan-card"
import PropTypes from "prop-types"
import SchoolClassesSelect from "components/school-classes/select"
import {Text} from "shared/base"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStep1 extends ShapeComponent {
  static propTypes = {
    schoolClass: PropTypes.instanceOf(SchoolClass)
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step1"})

    this.t = t
    this.currentUser = useCurrentUser()
    this.useStates({
      currentSubscriptions: undefined,
      plans: undefined,
      schoolClassesQuery: undefined
    })

    useMemo(() => {
      this.tt.loadPlans()
    }, [])

    useMemo(() => {
      if (this.currentUser) {
        this.setState({
          schoolClassesQuery: SchoolClass
            .ransack({
              s: "current_translation_name",
              with_contact_id: this.currentUser.contactId()
            })
            .preload(["school.account", "school.country"])
            .select({
              Account: ["id"],
              Country: ["countryCode"],
              School: ["id", "name"],
              SchoolClass: ["id", "name"]
            })
            .groupBy("id")
        })
      } else {
        this.setState({
          schoolClassesQuery: undefined
        })
      }
    }, [this.currentUser?.id()])

    useMemo(() => {
      if (this.currentUser && this.p.schoolClass) {
        this.tt.loadCurrentSubsriptions()
      } else {
        this.setState({currentSubscriptions: undefined})
      }
    }, [this.currentUser?.id(), this.p.schoolClass?.id()])
  }

  render() {
    return (
      <View dataSet={{component: "plans--sign-up-modal--step1"}}>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading>
            {this.t(".choose_the_plan_that_fits_you")}
          </Heading>
          <View style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Text style={{marginRight: 9}}>
              {this.t(".you_are_about_to_choose_a_plan_for_the_following_class")}
            </Text>
            {this.s.schoolClassesQuery &&
              <View style={{width: 140}}>
                <SchoolClassesSelect
                  defaultValue={this.p.schoolClass?.id()}
                  id="school_class"
                  onChange={this.tt.onSchoolClassChanged}
                  query={this.s.schoolClassesQuery}
                />
              </View>
            }
          </View>
          <View style={{display: "flex", flexDirection: "row", marginTop: 20}}>
            {this.s.plans?.map((plan) =>
              <View key={plan.id()} style={{width: 360, marginLeft: 10, marginRight: 10}}>
                <PlanCard
                  current={this.tt.isPlanCurrent(plan)}
                  onPlanChoosen={this.p.onPlanChoosen}
                  plan={plan}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    )
  }

  isPlanCurrent = (plan) => {
    if (this.s.currentSubscriptions?.length === 0 && plan.identifier() == "free") {
      return true
    }

    return Boolean(this.s.currentSubscriptions?.find((subscription) => subscription.planId() == plan.id()))
  }

  loadCurrentSubsriptions = async () => {
    const currentSubscriptions = await Subscription
      .ransack({
        school_class_id_eq: this.p.schoolClass.id(),
        with_active_period: 1
      })
      .select({Subscription: ["planId"]})
      .toArray()

    this.setState({currentSubscriptions})
  }

  loadPlans = async () => {
    const plans = await Plan
      .ransack({
        show_in_sign_up_modal_true: 1,
        s: "position"
      })
      .preload(["resources.periods.prices"])
      .select({
        Plan: ["description", "descriptionWithInlineStyling", "id", "identifier", "name", "subTitle"],
        PlanResource: ["id", "resourceName"],
        PlanResourcePeriod: ["id", "months", "name"],
        PlanResourcePeriodPrice: ["id", "price", "priceCurrency"]
      })
      .toArray()

    if (plans.length <= 0) throw new Error("Couldn't find any plans to show")

    this.setState({plans})
  }

  onSchoolClassChanged = ({options}) => {
    const schoolClass = dig(options, 0, "schoolClass")

    this.p.onSchoolClassChanged(schoolClass)
  }
}))
