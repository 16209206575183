import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {User} from "models"

export default memo(shapeComponent(class ComponentsFrontLayoutTopMenu extends ShapeComponent {
  static propTypes = {
    actions: PropTypes.node,
    className: PropTypes.string,
    currentUser: PropTypes.instanceOf(User),
    layout: PropTypes.string.isRequired,
    leftActions: PropTypes.node,
    userMenu: PropTypes.bool.isRequired
  }

  render() {
    const {actions, className, layout, leftActions, userMenu, ...restProps} = this.props

    return (
      <div className={classNames("components--front-layout--top-menu", className)} {...restProps}>
        <div className="top-menu-container">
          <div>
            {leftActions}
          </div>
          <div style={{display: "flex"}}>
            {actions}
          </div>
        </div>
      </div>
    )
  }
}))
