import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import {digg} from "diggerize"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {Text} from "shared/base"
import useAnythingUnicorn from "components/subscriptions/use-anything-unicorn"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUserUnicornTopBar extends ShapeComponent {
  static propTypes = propTypesExact({
    onUpgradePressed: PropTypes.func.isRequired
  })

  setup() {
    const anythingUnicorn = useAnythingUnicorn({
      userSubscriptions: {
        preload: ["last_period"],
        select: {
          Subscription: ["createdAt", "id"],
          SubscriptionPeriod: ["endsAt"]
        }
      }
    })
    const {t} = useI18n({namespace: "js.components.user.unicorn.top_bar"})
    const trialSubscription = digg(anythingUnicorn, "trialSubscription")
    let daysUntilTrialExpire

    if (trialSubscription) {
      const lastSubscriptionPeriodEndsAt = moment(trialSubscription.lastPeriod().endsAt())

      daysUntilTrialExpire = lastSubscriptionPeriodEndsAt.diff(moment().startOf("day"), "days")
    }

    this.setInstance({anythingUnicorn, daysUntilTrialExpire, t})
  }

  render() {
    const {anythingUnicorn, daysUntilTrialExpire, t} = this.tt
    const {schoolClassesWithUnicorn, schoolsThroughClassWithSchoolUnicorn, schoolsWithUnicorn} = anythingUnicorn
    const {onUpgradePressed} = this.p
    let mode

    if (schoolClassesWithUnicorn?.length > 0 || schoolsThroughClassWithSchoolUnicorn?.length > 0 || schoolsWithUnicorn?.length > 0) {
      // Dont show the top bar
    } else if (anythingUnicorn.hasUnicornTrial) {
      mode = "showHasTrial"
    } else if (anythingUnicorn.expiredUnicornTrial) {
      mode = "showTrialExpired"
    }

    if (!mode) {
      return null
    }

    return (
      <View
        dataSet={{component: "user--unicorn--top-bar", mode}}
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: 10,
          paddingRight: 25,
          paddingBottom: 10,
          paddingLeft: 25,
          backgroundColor: "#3b97ff"
        }}
      >
        <Text dataSet={{class: "unicorn-top-bar-text"}} style={{marginRight: 20, color: "#fff", fontSize: 16, fontWeight: "bold"}}>
          {mode == "showTrialExpired" && t(".your_trial_has_expired")}
          {mode == "showHasTrial" && daysUntilTrialExpire == 0 && t(".your_trial_period_expires_today", {days: daysUntilTrialExpire})}
          {mode == "showHasTrial" && daysUntilTrialExpire > 0 && t(".your_trial_period_expires_in_x_days", {days: daysUntilTrialExpire})}
        </Text>
        <Button color="white" onPress={onUpgradePressed} size="small" title={t(".read_more")} />
        <Button
          color="purple"
          dataSet={{class: "top-bar-upgrade-button"}}
          onPress={onUpgradePressed}
          size="small"
          style={{marginLeft: 15}}
          title={t(".upgrade")}
        />
      </View>
    )
  }
}))
