import {Pressable, View} from "react-native"
import React, {useMemo} from "react"
import {shapeComponent, ShapeComponent} from "set-state-compare/src/shape-component"
import {digs} from "diggerize"
import {HelperText} from "react-native-paper"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import {Text} from "shared/base"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

export default memo(shapeComponent(class ComponentsUtilsCheckbox extends ShapeComponent {
  static defaultProps = {
    value: undefined
  }

  static propTypes = propTypesExact({
    checked: PropTypes.bool.isRequired,
    dataSet: PropTypes.object,
    fontStyle: PropTypes.object,
    hint: PropTypes.node,
    label: PropTypes.string,
    labelContent: PropTypes.node,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChangeChecked: PropTypes.func,
    style: PropTypes.object,
    value: PropTypes.object
  })

  setup() {
    const {name, checked} = this.props
    const {wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "input"}})
    const {errors} = digs(wrapperOpts, "errors")

    this.errors = errors
    this.form = useForm()

    // Set initial value in form
    useMemo(() => {
      if (this.form && name) {
        this.form.setValue(name, checked)
      }
    }, [])
  }

  render() {
    const {errors} = this.tt
    const {checked, dataSet, fontStyle, hint, label, labelContent, style} = this.props
    const actualDataSet = Object.assign({}, dataSet, {checked})
    const viewStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 17,
      height: 17,
      backgroundColor: "#cbcbcb",
      borderRadius: 7
    }

    return (
      <View dataSet={actualDataSet} style={style}>
        <Pressable
          dataSet={{class: "checkbox-pressable"}}
          onPress={this.tt.onRadioButtonPressed}
          style={{display: "flex", flexDirection: "row", alignItems: "center"}}
        >
          <View style={viewStyle}>
            {checked &&
              <Text style={{color: "#4c93ff"}}>
                ✓
              </Text>
            }
          </View>
          {(label || labelContent) &&
            <View style={{marginLeft: 12}}>
              {label &&
                <Text style={fontStyle}>
                  {label}
                </Text>
              }
              {labelContent}
            </View>
          }
        </Pressable>
        {hint &&
          <View style={{marginTop: 4}}>
            {hint}
          </View>
        }
        {errors.length > 0 &&
          <HelperText dataSet={{class: "errors-helper-text"}} type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  onRadioButtonPressed = () => {
    const {form} = this.tt
    const {name} = this.props
    const newChecked = !this.p.checked

    if (form && name) {
      form.setValue(name, newChecked)
    }

    if (this.props.onChange) {
      this.p.onChange({
        checked: newChecked,
        label: this.p.label,
        value: this.p.value
      })
    }

    if (this.props.onChangeChecked) {
      this.p.onChangeChecked(newChecked)
    }
  }
}))
