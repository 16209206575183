import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import FlashMessage from "shared/flash-message"
import memo from "set-state-compare/src/memo"
import RenderHtml from "shared/render-html"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalPlanCard extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.plan_card"})

    this.t = t
  }

  render() {
    const {t} = this.tt
    const {current, plan} = this.p
    const subTitle = useMemo(
      () => ({html: `<div style="color: #fff;">${plan.subTitle()}</div>`}),
      [plan.id()]
    )

    return (
      <View
        dataSet={{
          component: "plans--sign-up-modal--plan-card",
          current
        }}
        style={{
          backgroundColor: "#fff",
          boxShadow: "rgba(0, 0, 0, .04) 0 1px 0, rgba(0, 0, 0, .05) 0 2px 7px, rgba(0, 0, 0, .06) 0 12px 22px",
          borderRadius: 12
        }}
      >
        <View style={{padding: 31, backgroundColor: "#1b4648", borderTopLeftRadius: 12, borderTopRightRadius: 12}}>
          <Text style={{color: "#fff", fontSize: "30px"}}>
            {plan.name()}
          </Text>
          <View style={{minHeight: 65}}>
            <RenderHtml source={subTitle} />
          </View>
        </View>
        <View style={{padding: 31}}>
          <RenderHtml dataSet={{class: "render-html-content"}} source={plan.descriptionWithInlineStyling()} />
        </View>
        <View style={{padding: 31}}>
          <Button
            dataSet={{component: "start-subscription-button", planId: plan.id()}}
            disabled={current}
            onPress={this.tt.onStartSubscriptionPressed}
            title={current ? t(".current_subscription") : t(".choose")}
          />
        </View>
      </View>
    )
  }

  onStartSubscriptionPressed = () => {
    if (this.p.current) {
      FlashMessage.alert(this.t(".you_are_already_subscribed_to_that_plan"))

      return
    }

    this.p.onPlanChoosen({plan: this.p.plan})
  }
}))
