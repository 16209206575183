import memo from "set-state-compare/src/memo"
import React from "react"
import {View} from "react-native"
const Column = memo(({style, ...restProps}) => {
  const actualStyle = Object.assign({flex: "1 1 0%"}, style)

  return (
    <View style={actualStyle} {...restProps} />
  )
})

const Row = memo(({style, ...restProps}) => {
  const actualStyle = Object.assign({flexFlow: "row"}, style)

  return (
    <View style={actualStyle} {...restProps} />
  )
})

const Table = memo(({style, ...restProps}) => {
  const actualStyle = Object.assign({}, style)

  return (
    <View style={actualStyle} {...restProps} />
  )
})

export {Column, Row, Table}
