import {useCallback, useMemo, useState} from "react"
import {Country} from "models"
import Deserializer from "@kaspernj/api-maker/build/deserializer"
import {digg} from "diggerize"
import globalEvents from "shared/events"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"

const defaultCountry = () => {
  const countryData = globalThis.CURRENTS?.country

  if (!countryData) return null

  const parsedCountryData = Deserializer.parse(countryData)

  // Might be a collection with preloaded relationships
  if (Array.isArray(parsedCountryData)) return parsedCountryData[0]

  if (parsedCountryData) {
    const country = new Country({data: parsedCountryData})

    return country
  }
}

const useCurrentCountry = () => {
  const [currentCountry, setCurrentCountry] = useState(() => defaultCountry())
  const onCurrentCountryChanged = useCallback(({country}) => {
    setCurrentCountry(country)
  }, [setCurrentCountry])

  const loadCurrentCountry = useCallback(async () => {
    const result = await Country.current()
    const currentCountry = digg(result, "current_country")

    setCurrentCountry(currentCountry)
  }, [])

  useMemo(() => {
    if (!currentCountry) {
      loadCurrentCountry()
    }
  }, [])

  useEventEmitter(globalEvents, "currentCountryChanged", onCurrentCountryChanged)

  return currentCountry
}

export default useCurrentCountry
