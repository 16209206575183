import {Contact, InvoiceInfo, SchoolClass, VatNumber} from "models"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import InputValidator from "shared/input-validator"
import isUserA from "components/users/is-a"
import {loading} from "components/layout/loading-blocker"
import memo from "set-state-compare/src/memo"
import presence from "shared/presence"
import PropTypes from "prop-types"
import React from "react"
import useCurrentCountry from "components/utils/use-current-country"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UtilsButton from "components/utils/button"
import UtilsInput from "components/utils/input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStepInvoiceInfo extends ShapeComponent {
  static propTypes = {
    schoolClass: PropTypes.instanceOf(SchoolClass).isRequired
  }

  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step_invoice_info"})
    const currentCountry = useCurrentCountry()

    this.t = t
    this.setInstance({
      country: this.p.schoolClass.school().country() || currentCountry,
      currentUser: useCurrentUser()
    })
    this.useStates({
      schoolName: this.p.schoolClass.school().name() || "",
      vatNumber: "",
      streetName: "",
      zipCode: "",
      city: "",
      emailForInvoice: "",
      noteOrOther: ""
    })
  }

  render() {
    const {country, t} = this.tt

    return (
      <View dataSet={{component: "plans--sign-up-modal--step-invoice-info"}}>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading>
            {t(".invoice_information")}
          </Heading>
        </View>
        <UtilsInput
          dataSet={{class: "school-name-input"}}
          label={t(".school_name")}
          onChangeText={this.setStates.schoolName}
          required
          style={{marginBottom: 15}}
          value={this.s.schoolName}
        />
        {country?.countryCode() != "US" &&
          <UtilsInput
            dataSet={{class: "vat-number-input"}}
            label={t(".vat_number")}
            onChangeText={this.setStates.vatNumber}
            placeholder={t(".vat_number_placeholder")}
            required={country?.countryCode() != "DK" && country?.countryCode() != "US"}
            style={{marginBottom: 15}}
            value={this.s.vatNumber}
          />
        }
        <UtilsInput
          dataSet={{class: "street-name-input"}}
          label={t(".street_name")}
          onChangeText={this.setStates.streetName}
          required
          style={{marginBottom: 15}}
          value={this.s.streetName}
        />
        <View style={{display: "flex", flexDirection: "row", marginBottom: 15}}>
          <View style={{width: "50%"}}>
            <UtilsInput
              dataSet={{class: "zip-code-input"}}
              label={t(".zip_code")}
              onChangeText={this.setStates.zipCode}
              required
              style={{marginRight: 5}}
              value={this.s.zipCode}
            />
          </View>
          <View style={{width: "50%"}}>
            <UtilsInput
              dataSet={{class: "city-input"}}
              label={t(".city")}
              onChangeText={this.setStates.city}
              required
              style={{marginLeft: 5}}
              value={this.s.city}
            />
          </View>
        </View>
        <UtilsInput
          dataSet={{class: "email-for-invoice-input"}}
          label={t(".email_for_invoice")}
          onChangeText={this.setStates.emailForInvoice}
          required
          style={{marginBottom: 15}}
          value={this.s.emailForInvoice}
        />
        <UtilsInput
          dataSet={{class: "note-or-other-input"}}
          label={t(".note_or_other")}
          onChangeText={this.setStates.noteOrOther}
          style={{marginBottom: 15}}
          value={this.s.noteOrOther}
        />
        <UtilsButton dataSet={{class: "submit-invoice-info-button"}} onPress={this.tt.onContinuePressed} title={t(".continue")} />
      </View>
    )
  }

  onContinuePressed = async () => {
    const {country, currentUser, t} = this.tt
    const {city, emailForInvoice, noteOrOther, schoolName, streetName, vatNumber, zipCode} = this.s

    if (isUserA(currentUser, "tester")) {
      if (vatNumber != "") {
        try {
          await loading(t(".checking_vat_number"), async () => {
            await VatNumber.lookup({vat_number: vatNumber})
          })
        } catch (error) {
          FlashMessage.errorResponse(error)

          return
        }
      }
    }

    const invoiceInfo = {
      name: schoolName,
      vatNumber: presence(vatNumber),
      streetName,
      zipCode,
      city,
      email: emailForInvoice,
      note: noteOrOther
    }

    const inputValidator = new InputValidator({
      inputs: invoiceInfo,
      translations: {
        city: InvoiceInfo.humanAttributeName("city"),
        email: InvoiceInfo.humanAttributeName("email"),
        name: Contact.humanAttributeName("name"),
        note: InvoiceInfo.humanAttributeName("note"),
        streetName: InvoiceInfo.humanAttributeName("streetName"),
        vatNumber: InvoiceInfo.humanAttributeName("vatNumber"),
        zipCode: InvoiceInfo.humanAttributeName("zipCode")
      }
    })

    inputValidator
      .validate("city", {presence: true})
      .validate("email", {presence: true})
      .validate("name", {presence: true})
      .validate("streetName", {presence: true})
      .validate("zipCode", {presence: true})

    if (country?.countryCode() != "DK" && country?.countryCode() != "US") {
      inputValidator.validate("vatNumber", {presence: true})
    }

    if (inputValidator.isInvalid()) {
      FlashMessage.alert(inputValidator.getErrorMessages().join(" "))
    } else {
      this.p.onInvoiceInfoSubmit({invoiceInfo})
    }
  }
}))
