import {AdminSelectContainerContext, styles as adminSelectStyles} from "components/admin/select-container"
import React, {useContext, useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import HayaSelect from "haya-select/src/select"
import {incorporate} from "incorporator"
import {InputContainerContext} from "components/inputs/input-container"
import memo from "set-state-compare/src/memo"
import {useForm} from "@kaspernj/api-maker/build/form"
import useInput from "@kaspernj/api-maker/build/use-input"

export default memo(shapeComponent(class WoofHayaSelect extends ShapeComponent {
  setup() {
    const {inputProps} = useInput({props: this.props, wrapperOptions: {type: "select"}})

    this.form = useForm()

    useMemo(() => {
      if (this.form && inputProps.name && inputProps.defaultValue) {
        this.form.setValue(inputProps.name, inputProps.defaultValue)
      }
    }, [])
  }

  render() {
    const adminSelectContainerContext = useContext(AdminSelectContainerContext)
    const inputContainerContext = useContext(InputContainerContext)
    const {onChange, styles = {}, woofInputStyling, ...restProps} = this.props

    if (adminSelectContainerContext == "withinAdminSelectContainer") {
      incorporate(styles, adminSelectStyles)
    }

    const selectContainerStyleActual = Object.assign({}, styles.selectContainer)

    if (this.form && restProps.name && restProps.values) {
      let value

      if (restProps.multiple) {
        value = restProps.values
      } else {
        value = restProps.values[0]
      }

      this.form.setValue(restProps.name, value)
    }

    if (inputContainerContext == "withinInputContainer") {
      selectContainerStyleActual.paddingTop = 17
      selectContainerStyleActual.paddingRight = 26
      selectContainerStyleActual.paddingBottom = 17
      selectContainerStyleActual.paddingLeft = 26
      selectContainerStyleActual.border = 0
      selectContainerStyleActual.background = "transparent"
    }

    styles.selectContainer = selectContainerStyleActual

    if (woofInputStyling) {
      if (!("optionPresentationText" in styles)) styles.optionPresentationText = {}

      styles.optionPresentationText.color = "#666"
      styles.optionPresentationText.fontSize ||= 17

      if (!("currentSelected" in styles)) styles.currentSelected = {}

      styles.currentSelected.overflow = "hidden"
      styles.currentSelected.whiteSpace = "nowrap"

      if (!("optionsContainer" in styles)) styles.optionsContainer = {}

      styles.optionsContainer.border = "1px solid #dcdcdc"

      selectContainerStyleActual.paddingTop = 17
      selectContainerStyleActual.paddingRight = 26
      selectContainerStyleActual.paddingBottom = 17
      selectContainerStyleActual.paddingLeft = 26
      selectContainerStyleActual.border = "1px solid #dcdcdc"
      selectContainerStyleActual.borderRadius = 10

      if (!("chevronContainer" in styles)) styles.chevronContainer = {}

      styles.chevronContainer.marginRight = undefined
    }

    return (
      <HayaSelect
        onChange={this.tt.onChange}
        styles={styles}
        {...restProps}
      />
    )
  }

  onChange = ({options, ...restProps}) => {
    // Set form value if any
    if (this.props.name && this.form) {
      const values = options.map((option) => option.value)
      let value

      if (this.props.multiple) {
        value = values
      } else {
        value = values[0]
      }

      this.form.setValue(this.props.name, value)
    }

    if (this.props.onChange) {
      this.props.onChange({options, ...restProps})
    }
  }
}))
