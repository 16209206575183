import React, {useMemo} from "react"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/inputs/button"
import ContentsPresentation from "components/contents/presentation"
import inflection from "inflection"
import isUserA from "components/users/is-a"
import memo from "set-state-compare/src/memo"
import {PageContent} from "models"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import Routes from "shared/routes"
import {Text} from "shared/base"
import useCreatedEvent from "@kaspernj/api-maker/build/use-created-event"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import useUpdatedEvent from "@kaspernj/api-maker/build/use-updated-event"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPageContentsPresentation extends ShapeComponent {
  static propTypes = propTypesExact({
    dataSet: PropTypes.object,
    defaultContent: PropTypes.node,
    identifier: PropTypes.string.isRequired,
    liquidVariables: PropTypes.object,
    style: PropTypes.object
  })

  setup() {
    const {t} = useI18n({namespace: "js.components.page_contents.presentation"})

    this.currentUser = useCurrentUser()
    this.t = t
    this.useStates({
      pageContent: undefined,
      pageContentLoaded: false
    })
    useMemo(() => {
      this.loadContent()
    }, [])
    this.useStates({
      hover: false
    })

    useCreatedEvent(PageContent, this.tt.onPageContentCreated)
    useUpdatedEvent(this.s.pageContent, this.tt.onPageContentUpdated)
  }

  async loadContent() {
    const pageContent = await PageContent
      .ransack({identifier_eq: this.p.identifier})
      .preload(["content_model"])
      .select({
        Content: ["id", "updatedAt"],
        PageContent: ["id"]
      })
      .first()

    this.setState({
      pageContent,
      pageContentLoaded: true
    })
  }

  render() {
    const {currentUser, t} = this.tt
    const {dataSet, defaultContent, identifier, liquidVariables, style} = this.props
    const {hover, pageContent, pageContentLoaded} = this.s
    const actualDataSet = Object.assign({component: "page-contents/presentation", identifier}, dataSet)
    const detectedLiquidVariables = liquidVariables ? Object.keys(liquidVariables).map((liquidVariable) => inflection.underscore(liquidVariable)) : null

    return (
      <View dataSet={actualDataSet} onPointerEnter={this.tt.onPointerEnter} onPointerLeave={this.tt.onPointerLeave} style={style}>
        {pageContentLoaded && hover && isUserA(currentUser, "admin") &&
          <View style={{position: "absolute", top: 0, left: 0, zIndex: 9}}>
            {pageContent &&
              <Button label={<>&#9998;</>} primary small to={Routes.superAdminPath({model: "PageContent", model_id: pageContent.id(), mode: "edit"})} />
            }
            {!pageContent &&
              <Button
                label={<>&#9998;</>}
                primary
                small
                to={
                  Routes.superAdminPath({
                    model: "PageContent",
                    mode: "new",
                    detected_liquid_variables: detectedLiquidVariables,
                    page_content: {identifier}
                  })
                }
              />
            }
          </View>
        }
        {pageContent &&
          <ContentsPresentation
            content={pageContent.contentModel()}
            liquidVariables={liquidVariables}
            reactNative
            resource={pageContent}
          />
        }
        {(() => {
          if (pageContentLoaded && (!pageContent || !pageContent.contentModel())) {
            if (defaultContent) {
              return defaultContent
            } else {
              return <Text>{t(".no_content_for_identifier_yet", {identifier})}</Text>
            }
          }
        })()}
      </View>
    )
  }

  onPageContentCreated = ({model: pageContent}) => {
    if (pageContent.identifier() == this.p.identifier) {
      this.loadContent()
    }
  }

  onPageContentUpdated = ({model: pageContent}) => {
    if (pageContent.identifier() == this.p.identifier) {
      this.loadContent()
    }
  }

  onPointerEnter = () => this.setState({hover: true})
  onPointerLeave = () => this.setState({hover: false})
}))
