import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import {digs} from "diggerize"
import {HelperText} from "react-native-paper"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
import useInput from "@kaspernj/api-maker/build/use-input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUtilsInput extends ShapeComponent {
  static defaultProps = {
    autoRows: false,
    required: false
  }

  static propTypes = {
    autoRows: PropTypes.bool.isRequired,
    label: PropTypes.string,
    minimumRows: PropTypes.number,
    name: PropTypes.string,
    onChangeText: PropTypes.func,
    required: PropTypes.bool.isRequired,
    style: PropTypes.object,
    styles: PropTypes.object
  }

  setup() {
    const {wrapperOpts} = useInput({props: this.props, wrapperOptions: {type: "input"}})
    const {errors} = digs(wrapperOpts, "errors")

    this.setInstance({errors})
  }

  render() {
    const {errors} = this.tt
    const {dataSet, label, onLayout, required} = this.props
    const {component, ...restDataSet} = dataSet || {}
    const actualDataSet = Object.assign(
      {component: classNames("utils/input-container", component), inputName: this.props.name},
      restDataSet
    )

    return (
      <View dataSet={actualDataSet} onLayout={onLayout} style={this.stylingFor("container")}>
        {label &&
          <View style={{marginLeft: 10}}>
            <Text style={{color: "#aaa", fontSize: 12}}>
              {label}{required && " *"}
            </Text>
          </View>
        }
        <View>
          {this.props.children}
        </View>
        {errors.length > 0 &&
          <HelperText dataSet={{class: "errors-helper-text"}} type="error">
            {this.errorMessages().join(". ")}
          </HelperText>
        }
      </View>
    )
  }

  errorMessages () {
    const {errors} = this.tt
    const errorMessages = []

    for (const error of errors) {
      for (const errorMessage of error.getErrorMessages()) {
        errorMessages.push(errorMessage)
      }
    }

    return errorMessages
  }
}))
