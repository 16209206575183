import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import classNames from "classnames"
import memo from "set-state-compare/src/memo"
import {Pressable} from "react-native"
import PropTypes from "prop-types"
import React from "react"
import {Text} from "shared/base"
import {useForm} from "@kaspernj/api-maker/build/form"
export default memo(shapeComponent(class ComponentsUtilsButton extends ShapeComponent {
  static defaultProps = {
    submit: false
  }

  static propTypes = {
    color: PropTypes.string,
    dataSet: PropTypes.object,
    disabled: PropTypes.bool,
    fontStyle: PropTypes.object,
    size: PropTypes.string,
    submit: PropTypes.bool.isRequired,
    title: PropTypes.string
  }

  setup() {
    this.form = useForm()
  }

  render() {
    const {color, dataSet, disabled, fontStyle, onPress, size, style, submit, title, ...restProps} = this.props
    const actualDataSet = Object.assign({disabled}, dataSet, {component: classNames("utils--button", dataSet?.component)})
    const actualFontStyle = Object.assign(
      {marginTop: 17, marginRight: 25, marginBottom: 17, marginLeft: 25, fontSize: "15px", color: "#fff", textAlign: "center"},
      fontStyle
    )
    const pressableStyle = Object.assign({backgroundColor: "#4c93ff", borderRadius: "5px"}, style)

    if (disabled) {
      pressableStyle.backgroundColor = "#c8c8c8"
    } else if (color == "green") {
      pressableStyle.backgroundColor = "#50b14e"
    } else if (color == "grey") {
      pressableStyle.backgroundColor = "#a9afb9"
    } else if (color == "purple") {
      pressableStyle.backgroundColor = "#d648dc"
    } else if (color == "red") {
      pressableStyle.backgroundColor = "#ff4c4c"
    } else if (color == "white") {
      pressableStyle.backgroundColor = "#fff"
      actualFontStyle.color = "#000"
    } else if (color) {
      throw new Error(`Unknown color: ${color}`)
    }

    if (size == "small") {
      actualFontStyle.marginTop = 5
      actualFontStyle.marginRight = 10
      actualFontStyle.marginBottom = 5
      actualFontStyle.marginLeft = 10

      actualFontStyle.fontSize = 15
    } else if (size) {
      throw new Error(`Unknown size: ${size}`)
    }

    return (
      <Pressable
        dataSet={actualDataSet}
        onPress={this.tt.onPress}
        style={pressableStyle}
        {...restProps}
      >
        <Text style={actualFontStyle}>
          {title}
        </Text>
      </Pressable>
    )
  }

  onPress = () => {
    if (this.props.submit && this.form) {
      this.form.submit()
    }

    if (this.props.onPress) {
      this.props.onPress()
    }
  }
}))
