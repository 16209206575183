import {School, SchoolClass, Subscription} from "models"
import {useCallback, useEffect, useMemo} from "react"
import ModelEvents from "@kaspernj/api-maker/build/model-events"
import moment from "shared/moment"
import useCurrentUser from "@kaspernj/api-maker/build/use-current-user"
import useShape from "set-state-compare/src/use-shape"

const loadExpiredUnicornTrial = async (s) => {
  const thirtyDaysAgo = moment().subtract(30, "days").toDate()
  const subscription = await Subscription
    .ransack({
      plan_identifier_eq: "unicorn_trial",
      resource_type_eq: "User",
      resource_id_eq: s.m.currentUser.id(),
      without_active_period: true,
      last_period_ends_at_gteq: thirtyDaysAgo
    })
    .select({Subscription: ["createdAt"]})
    .first()

  s.set({expiredUnicornTrial: subscription})
}

const loadSchoolsWithUnicorn = async (s) => {
  const schoolsWithUnicorn = await School
    .ransack({
      contact_children_relationships_child_id_eq: s.m.currentUser.contactId(),
      subscriptions_plan_identifier_eq: "unicorn",
      with_active_subscription: true
    })
    .toArray()

  s.set({schoolsWithUnicorn})
}

const loadSchoolClassesWithUnicorn = async (s) => {
  const schoolClassesWithUnicorn = await SchoolClass
    .ransack({
      contact_children_relationships_child_id_eq: s.m.currentUser.contactId(),
      subscriptions_plan_identifier_eq: "unicorn",
      with_active_subscription: true
    })
    .toArray()

  s.set({schoolClassesWithUnicorn})
}

const loadSchoolsThroughClassWithSchoolUnicorn = async (s) => {
  const schoolsThroughClassWithSchoolUnicorn = await School
    .ransack({
      school_classes_contact_children_relationships_child_id_eq: s.m.currentUser.contactId(),
      subscriptions_plan_identifier_eq: "unicorn",
      with_active_subscription: true
    })
    .toArray()

  s.set({schoolsThroughClassWithSchoolUnicorn})
}

const loadUserUnicornSubscriptions = async (s) => {
  const userUnicornSubscriptionsQuery = await Subscription
    .ransack({
      plan_identifier_eq_any: ["unicorn", "unicorn_plus", "unicorn_trial"],
      resource_type_eq: "User",
      resource_id_eq: s.m.currentUser.id(),
      with_active_period: true
    })
    .preload("plan")

  if (s.props?.userSubscriptions?.select) userUnicornSubscriptionsQuery.select(s.props.userSubscriptions.select)
  if (s.props?.userSubscriptions?.preload) userUnicornSubscriptionsQuery.preload(s.props.userSubscriptions.preload)

  const userUnicornSubscriptions = await userUnicornSubscriptionsQuery.toArray()
  const trialSubscription = userUnicornSubscriptions.find((subscription) => subscription.plan()?.identifier() == "unicorn_trial")

  s.set({
    hasUnicornTrial: Boolean(trialSubscription),
    trialSubscription,
    userUnicornSubscriptions
  })
}

const useAnythingUnicorn = (props) => {
  const s = useShape(props)
  const currentUser = useCurrentUser()

  s.meta.currentUser = currentUser
  s.useStates({
    anythingUnicorn: null,
    expiredUnicornTrial: null,
    hasUnicornTrial: false,
    schoolClassesWithUnicorn: null,
    schoolsWithUnicorn: null,
    schoolsThroughClassWithSchoolUnicorn: null,
    trialSubscription: null,
    userUnicornSubscriptions: null
  })

  if (
    (
      s.s.schoolsWithUnicorn?.length > 0 ||
      s.s.schoolClassesWithUnicorn?.length > 0 ||
      s.s.userUnicornSubscriptions?.length > 0 ||
      s.s.schoolsThroughClassWithSchoolUnicorn?.length > 0
    ) &&
    s.s.anythingUnicorn !== true
  ) {
    s.set({anythingUnicorn: true})
  } else if (
    s.s.schoolsWithUnicorn?.length === 0 &&
    s.s.schoolClassesWithUnicorn?.length === 0 &&
    s.s.userUnicornSubscriptions?.length === 0 &&
    s.s.schoolsThroughClassWithSchoolUnicorn.length === 0 &&
    s.s.anythingUnicorn !== false
  ) {
    s.set({anythingUnicorn: false})
  }

  useMemo(() => {
    if (currentUser) {
      loadExpiredUnicornTrial(s)
      loadSchoolsThroughClassWithSchoolUnicorn(s)
      loadSchoolClassesWithUnicorn(s)
      loadSchoolsWithUnicorn(s)
      loadUserUnicornSubscriptions(s)
    } else {
      s.set({
        expiredUnicornTrial: null,
        hasUnicornTrial: false,
        schoolClassesWithUnicorn: null,
        schoolsThroughClassWithSchoolUnicorn: null,
        schoolsWithUnicorn: null,
        trialSubscription: null,
        userUnicornSubscriptions: null
      })
    }
  }, [currentUser?.id()])

  const onStartUnicornUserTrial = useCallback(() => {
    loadUserUnicornSubscriptions(s)
  }, [])

  useEffect(() => {
    if (currentUser) {
      const webSocketSubscription = ModelEvents.connect(currentUser, "start_unicorn_user_trial", onStartUnicornUserTrial)

      return () => {
        webSocketSubscription.unsubscribe()
      }
    }
  }, [currentUser?.id()])

  return s.state
}

export default useAnythingUnicorn
