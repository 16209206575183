import BaseValidator from "./base"
import I18n from "shared/i18n"

export default class PresenceValidator extends BaseValidator {
  isValid() {
    const stringValue = `${this.getValue()}`.trim()

    if (stringValue.match(/^\s*$/)) {
      return false
    }

    return true
  }

  getErrorMessages() {
    return [
      I18n.t("js.input_validator.validators.presence.name_must_be_present", {name: this.getName()})
    ]
  }
}
