import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import React from "react"
import {TextInput} from "react-native"

export default memo(shapeComponent(class ComponnetsUtilsInputFieldOptimised extends ShapeComponent {
  static propTypes = {
    stateComponent: PropTypes.object,
    stateName: PropTypes.string
  }

  render() {
    const {onChangeText, stateComponent, stateName, ...restProps} = this.props

    return (
      <TextInput onChangeText={this.tt.onChangeText} {...restProps} />
    )
  }

  onChangeText = (newValue) => {
    const {onChangeText, stateComponent, stateName} = this.props

    if (stateComponent && stateName) {
      stateComponent.state[stateName] = newValue
    }

    if (onChangeText) {
      onChangeText(newValue)
    }
  }
}))
