import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import {Account} from "models"
import AddCreditCardButton from "components/billing/add-credit-card-button"
import memo from "set-state-compare/src/memo"
import PropTypes from "prop-types"
import propTypesExact from "prop-types-exact"
import React from "react"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStepCreditCard extends ShapeComponent {
  static propTypes = propTypesExact({
    account: PropTypes.instanceOf(Account).isRequired
  })

  render() {
    return (
      <View dataSet={{component: "plans--sign-up-modal--step-credit-card"}}>
        <AddCreditCardButton account={this.p.account} onCreditCardAdded={this.tt.onCreditCardAdded} primary />
      </View>
    )
  }

  onCreditCardAdded = () => {
    if (this.props.onCreditCardAdded) {
      this.props.onCreditCardAdded()
    }
  }
}))
