import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome"
import memo from "set-state-compare/src/memo"
import React from "react"
import TextInput from "./text-input"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsUtilsInputField extends ShapeComponent {
  render() {
    const {icon, style, ...restProps} = this.props
    const actualStyle = this.stylingFor("textInput", Object.assign(
      {
        backgroundColor: "#fff",
        border: "1px solid #dcdcdc",
        borderRadius: 10,
        boxShadow: "0 8px 10px 0 rgba(0, 0, 0, 0.07)",
        paddingTop: 17,
        paddingRight: 26,
        paddingBottom: 17,
        paddingLeft: 26,
        color: "#666",
        fontSize: "17px"
      },
      style
    ))

    if (icon) {
      actualStyle.paddingRight = 38
    }

    return (
      <>
        {icon &&
          <View
            style={{
              position: "absolute",
              right: 0,
              height: "100%"
            }}
          >
            <FontAwesomeIcon
              name={icon}
              style={{
                marginRight: 15,
                marginVertical: "auto",
                fontSize: 16,
                color: "#aaa"
              }}
            />
          </View>
        }
        <TextInput
          placeholderTextColor="#b0b0b0"
          style={actualStyle}
          {...restProps}
        />
      </>
    )
  }
}))
