import {Column, Row, Table} from "components/utils/table"
import {ShapeComponent, shapeComponent} from "set-state-compare/src/shape-component"
import Button from "components/utils/button"
import Checkbox from "components/utils/checkbox"
import FlashMessage from "shared/flash-message"
import Heading from "components/utils/heading"
import I18n from "shared/i18n"
import memo from "set-state-compare/src/memo"
import moment from "shared/moment"
import MoneyFormatter from "@kaspernj/api-maker/build/money-formatter"
import React from "react"
import {Text} from "shared/base"
import useI18n from "i18n-on-steroids/src/use-i18n.mjs"
import UsersAcceptTermsHint from "components/users/accept-terms-hint"
import {View} from "react-native"

export default memo(shapeComponent(class ComponentsPlansSignUpModalStepConfirm extends ShapeComponent {
  setup() {
    const {t} = useI18n({namespace: "js.components.plans.sign_up_modal.step_confirm"})

    this.t = t
    this.useStates({
      termsAndConditionsChecked: false
    })
  }

  render() {
    const {t} = this.tt
    const {paymentMethod, period, price, schoolClass} = this.p
    const renewalDate = moment().add(period.months(), "months").toDate()
    const renewalDateText = I18n.l("date.formats.default", renewalDate)

    return (
      <View dataSet={{component: "plans--sign-up-modal--step-confirm"}}>
        <View style={{display: "flex", alignItems: "center"}}>
          <Heading>{t(".confirm_upgrade")}</Heading>
        </View>
        <Text style={{marginBottom: 5, fontSize: 20, textAlign: "center"}}>
          {t(".the_license_applies_to_class_name", {class_name: schoolClass.interpretedName()})}
        </Text>
        <Text style={{marginBottom: 20, fontSize: 20, textAlign: "center"}}>
          {t(".the_subscription_starts_today_and_will_automatically_renew_at_renewal_date", {renewal_date: renewalDateText})}
        </Text>
        <Table>
          <Row>
            <Column>
              <Text style={{fontSize: 20}}>
                {t(".price_without_vat")}
              </Text>
            </Column>
            <Column>
              <Text style={{fontSize: 20, textAlign: "right"}}>
                {MoneyFormatter.format(price?.price())}
              </Text>
            </Column>
          </Row>
        </Table>
        <Text style={{marginTop: 30, marginBottom: 5, fontSize: 16, textAlign: "center"}}>
          {paymentMethod.identifier() == "invoice" && t(".invoice_text")}
          {paymentMethod.identifier() == "ean_invoice" && t(".ean_invoice_text")}
          {paymentMethod.identifier() == "credit_card" && t(".credit_card_text")}
        </Text>
        <Text style={{marginBottom: 15, fontSize: 16, textAlign: "center"}}>
          {t(".the_subscription_will_be_renewed_automatically_unless_canceled")}
        </Text>
        <Text style={{marginBottom: 30, fontSize: 16, textAlign: "center"}}>
          {t(".if_you_click_continue_then_you_will_accept_our_terms")}
        </Text>
        <Checkbox
          checked={this.s.termsAndConditionsChecked}
          dataSet={{class: "terms-and-conditions-checkbox"}}
          fontStyle={{fontSize: 16}}
          hint={<div style={{color: "#666", fontSize: 12}}><UsersAcceptTermsHint /></div>}
          label={t(".i_have_read_and_consent_to_the_terms_and_conditions")}
          onChangeChecked={this.tt.onChangeChecked}
          style={{marginBottom: 30}}
        />
        <Button color="green" dataSet={{class: "confirm-button"}} onPress={this.tt.onConfirmUpgradePressed} title={t(".confirm_upgrade")} />
      </View>
    )
  }

  onChangeChecked = (newChecked) => this.setState({termsAndConditionsChecked: newChecked})

  onConfirmUpgradePressed = () => {
    if (!this.s.termsAndConditionsChecked) {
      FlashMessage.alert(this.t(".please_read_and_consent_to_our_terms_and_conditions"))

      return
    }

    this.p.onConfirmUpgrade()
  }
}))
